import "./Dashboard.css";
import hello from "../../../../assets/hello.svg";
import BarChart from "../Charts/bars";
import SubscriberStats from './SubscriberStats'
import GrowthStatistics from './GrowthStats'
import { Loader } from "../../../../shared";
import { useState } from "react";


const Dashboard = (props) => {
  const [statsPeriod, setStatsPeriod] = useState("ninety")

  const filterSubsTimePeriod = e => {
    const filteredPeriod = e.target.value
    setStatsPeriod(filteredPeriod)
  }

  let { total_subs, total_invalid, unique_owners, total_subs90, total_invalid90, unique_owners90 } = props.data
  const loading = props.loading
  return (
    <main>

      {loading ? (
        <div className="loader__container">
          <Loader />
          <h3>Loading....</h3>
        </div>
      ) : (
        <div className="main__container">
          {/* <!-- MAIN TITLE STARTS HERE --> */}

          <div className="main__title">
            <img src={hello} alt="hello" />
            <div className="main__greeting">
              <h1>UCC ADMIN</h1>
              <p>Welcome to your admin dashboard</p>
            </div>
            <select name="slct" id="slct" className='table__select title__select' onChange={filterSubsTimePeriod}>
              <option defaultValue disabled>Filter by Period</option>
              <option value="ninety">90 DAYS</option>
              <option value="all">ALL TIME</option>
            </select>
          </div>

          {/* <!-- MAIN TITLE ENDS HERE --> */}

          {/* <!-- MAIN CARDS STARTS HERE --> */}
          {statsPeriod == "ninety" ? (
            <div className="main__cards">
              <div className="card">
                <i
                  className="fa fa-user-o fa-2x text-lightblue"
                  aria-hidden="true"
                ></i>
                <div className="card_inner">
                  <p className="text-primary-p">Total Simcards (90 Days)</p>
                  <span className="font-bold text-title">{total_subs90}</span>
                </div>
              </div>

              <div className="card">
                <i className="fa fa-calendar fa-2x text-red" aria-hidden="true"></i>
                <div className="card_inner">
                  <p className="text-primary-p">Total Invalids (90 Days)</p>
                  <span className="font-bold text-title">{total_invalid90}</span>
                </div>
              </div>

              <div className="card">
                <i
                  className="fa fa-user-o fa-2x text-lightblue"
                  aria-hidden="true"
                ></i>
                <div className="card_inner">
                  <p className="text-primary-p">Total Subscribers (90 Days)</p>
                  <span className="font-bold text-title">{unique_owners90}</span>
                </div>
              </div>

              <div className="card">
                <i
                  className="fa fa-thumbs-up fa-2x text-green"
                  aria-hidden="true"
                ></i>
                <div className="card_inner">
                  <p className="text-primary-p">Active MNOs</p>
                  <span className="font-bold text-title">5</span>
                </div>
              </div>
            </div>
          ) : (
            <div className="main__cards">
              <div className="card">
                <i
                  className="fa fa-user-o fa-2x text-lightblue"
                  aria-hidden="true"
                ></i>
                <div className="card_inner">
                  <p className="text-primary-p">Total Simcards (All Time)</p>
                  <span className="font-bold text-title">{total_subs}</span>
                </div>
              </div>

              <div className="card">
                <i className="fa fa-calendar fa-2x text-red" aria-hidden="true"></i>
                <div className="card_inner">
                  <p className="text-primary-p">Total Invalids (All Time)</p>
                  <span className="font-bold text-title">{total_invalid}</span>
                </div>
              </div>

              <div className="card">
                <i
                  className="fa fa-user-o fa-2x text-lightblue"
                  aria-hidden="true"
                ></i>
                <div className="card_inner">
                  <p className="text-primary-p">Total Subscribers (All Time)</p>
                  <span className="font-bold text-title">{unique_owners}</span>
                </div>
              </div>

              <div className="card">
                <i
                  className="fa fa-thumbs-up fa-2x text-green"
                  aria-hidden="true"
                ></i>
                <div className="card_inner">
                  <p className="text-primary-p">Active MNOs</p>
                  <span className="font-bold text-title">5</span>
                </div>
              </div>
            </div>
          )}

          {/* <!-- MAIN CARDS ENDS HERE --> */}

          {/* <!-- CHARTS STARTS HERE --> */}
          <div className="charts">
            <SubscriberStats />
            <GrowthStatistics />
          </div>


          <div className="charts__visualization">
            <BarChart />
          </div>

          {/* <div className="charts__visualization">
          <div className="charts__left">
            <div className="charts__left__title">
              <div>
                <h1>Monthly Compliance Report</h1>
              </div>
              <i className="fas fa-calender-week" aria-hidden="true"></i>
            </div>
            <HorizontalBarChart />
          </div>
        </div> */}
        </div>
      )}
    </main >
  );
};

export default Dashboard;
