import "./login.css";
import { useState, useEffect, Fragment } from 'react';
import axios from 'axios'
import ApiCallHandler from '../../../../utils/network'
import URL from '../../../../utils/constants'

const baseUrl = URL;

const apiCallResolver = new ApiCallHandler(baseUrl, axios)

const Login = (props) => {

    const [emailAddress, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [invalidLogin, setInvalidLogin] = useState(false)

    const updateEmail = e => {
        setEmail(e.target.value)
      }
    
      const updatePassword = e => {
        setPassword(e.target.value)
      }

    
      const authentication = async () => {
        let data = {
            "email_address": emailAddress,
            "password": password
        }

        await apiCallResolver.postRequest('/accounts/login', data)
            .then((response) => {
                if(response.data.status===404){
                    console.log("failed")
                    setInvalidLogin(true)
                    return false
                }
                localStorage.setItem("token", response.data.token);
                localStorage.setItem("role", response.data.data.category_id);
                localStorage.setItem("mno_id", response.data.data.mno_id);
                localStorage.setItem("data", response.data.data);
                const role = response.data.data.category_id
                if(role ===1 || role === 2){
                    props.history.push("/dashboard")
                }else{
                    props.history.push("/telcointerface")
                }
            
                return true
            }).catch((err) => {
                return err
        })
    }

    return (
        <Fragment>
            <div className="login">
                {invalidLogin ? (
                    <h1>Invalid Username or Password, Try Again</h1>
                ) : (
                    <h1>Login to Dashboard</h1>
                    )
                }

                <form method="post" action="" onSubmit={e => {
                    e.preventDefault()
                    authentication()
                }}>
                    <p>
                        <input type="text" name="login" placeholder="Username or Email" onChange={updateEmail}/>
                    </p>
                    <p>
                        <input type="password" name="password" placeholder="Password" onChange={updatePassword}/>
                    </p>
                            <p class="remember_me">
                                <label>
                                    <input type="checkbox" name="remember_me" id="remember_me"/>
                                        Remember me on this computer
                                </label>
                            </p>
                        <p className="submit"><input type="submit" name="commit"/></p>
                </form>
            </div>

            <div class="login-help">
                <p>Forgot your password? <a href="#">Click here to reset it</a>.</p>
            </div>
        </Fragment>
    );
};

export default Login;