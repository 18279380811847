import React, { useMemo, useState, useEffect } from "react";
import {
  ModalProvider,
  Modal,
  useModal,
  ModalTransition,
} from 'react-simple-hook-modal';
import ApiCallHandler from '../../../../utils/network'
import URL from '../../../../utils/constants'


import Table from "../../../../shared/components/Tables/Table";
import axios from 'axios'

const baseUrl = URL;

const apiCallResolver = new ApiCallHandler(baseUrl, axios)

function ExcessTable() {
    const { isModalOpen, openModal, closeModal } = useModal();
    const [ exceptionData, setExceptionData ] = useState([])
    const [loading, setLoading] = useState(true)
    const [exceptionloaded, setExceptionLoaded] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')
    const [subscriberSims, setSubscriberSims] = useState([])
    const [idNo, setIdNumber] = useState('')

    useEffect(() => {
      async function fetchExcessData() {
        await apiCallResolver.getRequest('/reporting/exception/above10')
            .then((response) => {
            setExceptionData(response.data.data)
            setLoading(false)
            })
            .catch((err) => {
            console.log(err)
            return err
            })
      }

      fetchExcessData();
    }, []);

    const fetchExcessDetails = async (id_no) => {
        setIdNumber(id_no)
        await apiCallResolver.postRequest('/reporting/simcards', { "nin": id_no })
          .then((response) => {
            setSubscriberSims(response.data.data)
            openModal()
          })
          .catch((err) => {
            console.log(err)
            return err
          })
      }


  const columns = useMemo(
    () => [
      {
        // first group - TV Show
        Header: "Personal Details",
        // First group columns
        columns: [
          {
            Header: "First Name",
            accessor: "f_name"
          },
          {
            Header: "Last Name",
            accessor: "s_name"
          }
        ]
      },
      {
        // Second group - Details
        Header: "ID Details",
        // Second group columns
        columns: [
          {
            Header: "ID NUM",
            accessor: "id_no"
          },
          {
            Header: "SIM COUNT",
            accessor: "count"
          },
        ]
      },
      {
        Header: "Number of MSISDNs",
        columns: [
          {
            Header: "Actions",
            Cell: ({ cell }) => (
              <button value={cell.row.values.name} onClick={e => fetchExcessDetails(cell.row.original.id_no)} className="table__button">
                VIEW ALL MSIDNS
              </button>)
          }
        ]
      }
    ],
    []
  );


  return (
    <>
      <div className="main__container">

        {loading ? (
          <h1>loading</h1>
        ) : (
          <div className="table__container">
            <h2>Excess Sim report (NINS with more than 10 sims across Networks)</h2>
            <div className="table__components">
              <div name="search">
                <input type="text" class="searchTerm" placeholder="Search by MSISDN or ID Number"/>
                <button type="submit" class="searchButton">
                  <i class="fa fa-search"></i>
                </button>
              </div>

              <div class="buttons">
                <button class="blob-btn">
                  Export To Excel
                  <span class="blob-btn__inner">
                    <span class="blob-btn__blobs">
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                    </span>
                  </span>
                </button>
                <br/>

              {/* <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                <defs>
                  <filter id="goo">
                    <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10"></feGaussianBlur>
                    <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7" result="goo"></feColorMatrix>
                    <feBlend in2="goo" in="SourceGraphic" result="mix"></feBlend>
                  </filter>
                </defs>
              </svg> */}
             </div>

            </div>
              <div>
                <Table columns={columns} data={exceptionData} />
              </div>
          </div>
          )}
        </div>

        <Modal
        id="any-unique-identifier"
        isOpen={isModalOpen}
        transition={ModalTransition.TOP_DOWN}
      >
        <div className="modal__container">
          <div className="item modal__header">
            <h2>Subscriber ID Number: {idNo}</h2>
          </div>
          <div className="item modal__content">
            <h2>Msisdns Held</h2>
            <ul className="sim__container">
              {
                subscriberSims.map((sim) => {
                  return <li className="badge" key={sim.msisdn}>{sim.msisdn}</li>
                })
              }
            </ul>
          </div>
          <div className="item modal__footer">
            <button className="grid_order table__button" onClick={closeModal}>Close</button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ExcessTable;