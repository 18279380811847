import React, { useMemo, useState, useEffect } from "react";
import "./Sidebar.css";
import logo from "../../../assets/ucc.png";
import {Link, NavLink} from 'react-router-dom'

const Sidebar = ({ sidebarOpen, closeSidebar}, props) => {
  const [role, setRole] = useState(1)

  useEffect(() => {
    setRole(parseInt(localStorage.getItem("role")))
    console.log(role)
  }, [role])

  let className = 'sidebar__link';
  if (props.isActive) {
    className += ' active_menu_link';
  }

  return (
    <div className={sidebarOpen ? "sidebar_responsive" : ""} id="sidebar">
      <div className="sidebar__title">
        <div className="sidebar__img">
          <img src={logo} alt="logo" />
        </div>
        <i
          onClick={() => closeSidebar()}
          className="fa fa-times"
          id="sidebarIcon"
          aria-hidden="true"
        ></i>
      </div>

      {role === 1 || role === 2 ? (
          <div className="sidebar__menu">
          <div className={className}>
            <i className="fa fa-home"></i>
            <NavLink to="/dashboard">Dashboard</NavLink>
          </div>
          <h2>REPORTs</h2>
          <div className={className}>
            <i className="fa fa-id-badge"></i>
            <NavLink to="/reporting">Subscribers</NavLink>
          </div>
          <div className={className} >
            <i className="fa fa-user-secret" aria-hidden="true"></i>
            <NavLink to="/marketshare">Market Share</NavLink>
          </div>
          <div className={className} >
            <i className="fa fa-briefcase" aria-hidden="true"></i>
            <NavLink to="/compliance">Exception Report</NavLink>
          </div>
          <div className={className} >
            <i className="fa fa-calculator" aria-hidden="true"></i>
            <NavLink to="/excesssim">Excess Sim Report</NavLink>
          </div>
          <div className={className} >
            <i className="fa fa-building-o"></i>
            <NavLink to="/data">Data Management</NavLink>
          </div>
          
          {/* <h2>AUDIT</h2>
          <div className="sidebar__link">
            <i className="fa fa-money"></i>
            <NavLink to="#">Audit Track</NavLink>
          </div>
          <div className="sidebar__link">
            <i className="fa fa-briefcase"></i>
            <NavLink to="#">Audit Reporting</NavLink>
          </div> */}
  
          <h2>ADMIN</h2>
          <div className="sidebar__link">
            <i className="fa fa-user-secret" aria-hidden="true"></i>
            <NavLink to="#">Admin Management</NavLink>
          </div>
          <div className="sidebar__logout">
          <i className="fa fa-power-off"></i>
          <Link to="/">Log out</Link>
        </div>
          </div>
      ) : (
        <div className="sidebar__menu">
        <h2>TELCO INTERFACES</h2>
        <div className="sidebar__link">
          <i className="fa fa-wrench" aria-hidden="true"></i>
          <NavLink to="/telcointerface">Check Sim Limit</NavLink>
        </div>
        <div className="sidebar__link">
          <i className="fa fa-briefcase" aria-hidden="true"></i>
          <NavLink to="#">Profile</NavLink>
        </div>
        <div className="sidebar__link">
          <i className="fa fa-user-secret" aria-hidden="true"></i>
          <NavLink to="#">Access Control</NavLink>
        </div>
        <div className={className} >
            <i className="fa fa-briefcase" aria-hidden="true"></i>
            <NavLink to="/compliance">Exception Report</NavLink>
        </div>
        <div className="sidebar__logout">
          <i className="fa fa-power-off"></i>
          <Link to="/">Log out</Link>
        </div>
      </div>
      )}
    </div>
  );
};

export default Sidebar;
