import React from 'react';
import {Bar} from 'react-chartjs-2';
import { useState, useEffect } from "react";
import ApiCallHandler from '../../../../utils/network'
import URL from '../../../../utils/constants'
import axios from 'axios'

const baseUrl = URL;

const apiCallResolver = new ApiCallHandler(baseUrl, axios)

export default function BarChart(){
    const [chartData, setChartData] = useState([])
    const [loading, setLoading] = useState(true)
    const [callData, setCallData] = useState([])
    const [year, setYear] = useState("2021")

    useEffect(()=> {
      async function fetchChartData(){
        await apiCallResolver.getRequest('/reporting/charts')
        .then((response) => {
          setCallData(response.data.data)
          setChartData(response.data.data["2021"])
          setLoading(false)
        }).catch((err) => {
          console.log(err)
          return err
        })
      }

    fetchChartData()
    }, [])

    const filterChartData = e => {
      const filteredYear = e.target.value
      setYear(e.target.value)
      setChartData(callData[filteredYear])
    }

    const data = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      datasets: [
        {
          label: 'Overall Growth',
          backgroundColor: 'rgba(8, 241, 241, 0.4)',
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: chartData
        }
      ]
    };

    return (
      <div className="charts__left">
        <div className="charts__left__title">
          <div className="chart__components">
            <h1>Monthly Growth (New SimCards) {year}</h1>
            <select name="slct" id="slct" className='table__select' onChange={filterChartData}>
                <option defaultValue disabled>Filter by Year</option>
                <option value="2021">2021</option>
                <option value="2020">2020</option>
              </select>
          </div>
          <i className="fas fa-calender-week" aria-hidden="true"></i>
        </div>
          <Bar data={data} height={120}/>
      </div>
    )
};