import React from "react";
import "./Loader.css"

function Loader2(){

    return (
        <div class="loader">Loading...</div>
    )
}

export default Loader2;