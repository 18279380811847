import "./Dashboard.css";
import {
  Link
} from "react-router-dom";
import LineChart from "../Charts/lines";
import ReportingTable from "../ReportingDashboard/ReportingTable";


const ReportingDashboard = () => {

  return (
      <ReportingTable />
  );
};

export default ReportingDashboard;
