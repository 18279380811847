import "./Navbar.css";
import avatar from "../../../assets/avatar.svg";
import {
  Link
} from "react-router-dom";


const Navbar = ({ sidebarOpen, openSidebar }) => {
  return (
    <nav className="navbar">
      <div className="nav_icon" onClick={() => openSidebar()}>
        <i className="fa fa-bars" aria-hidden="true"></i>
      </div>
      <div className="navbar__left">
        {/* <a href="#">Subscribers</a>
        <a href="#">Video Management</a> */}
        <Link to="#" className="active_link">
          Admin
        </Link>
        {/* <a href="https://mc3.cryptosavannah.com:4434/ucckyc/?chain=default&page=view&stream=713e81808e63e0caf988102bfaa6fdb8e1bb1027df697a4ced9bcff2dd6d9bc0" target="_blank">
          Blockchain Controller
        </a> */}
      </div>
      <div className="navbar__right">
        
        <a href="#">
          <i className="fa fa-clock-o" aria-hidden="true"></i>
        </a>
        <a href="#!">
          <img width="30" src={avatar} alt="avatar" />
        </a>
      </div>
    </nav>
  );
};

export default Navbar;