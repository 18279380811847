import {useState, useEffect} from 'react';
import ApiCallHandler from '../../../../utils/network'
import URL from '../../../../utils/constants'
import axios from 'axios'

const baseUrl = URL;

const apiCallResolver = new ApiCallHandler(baseUrl, axios)

const GrowthStatistics = () => {
    const [growthStats, setGrowthStats] = useState([])
    const [loading, setLoading] = useState(true)
    const [growthFilter, setGrowthFilter] = useState({})

    useEffect(() => {
        async function fetchGrowthData() {  
          await apiCallResolver.getRequest('/reporting/statistics/growth')
            .then((response) => {
              const data = response.data.data
              setGrowthStats(data.all)
              setGrowthFilter(data)
              setLoading(false)
            }).catch((err) => {
              console.log(err)
              return err
            })
        }
    
        fetchGrowthData()
      }, [])

    const filterGrowthBreakDown = e => {
      const filteredMno = e.target.value
      setGrowthStats(growthFilter[filteredMno])
    }


   return (
        <div className="charts__right">
            <div className="charts__right__title">
              <div>
                <h1>Growth Statistics</h1>
                <p>Subscriber Growth (April 2021)</p>
              </div>
              <select name="slct" id="slct" className='table__select' onChange={filterGrowthBreakDown}>
                <option defaultValue disabled>Filter by MNO</option>
                <option value="all">ALL</option>
                <option value="mtn">MTN</option>
                <option value="airtel">AIRTEL</option>
                <option value="utl">UTL</option>
                <option value="tangerine">TANGERINE</option>
                <option value="smile">SMILE</option>
              </select>
              <i className="fas fa-calender-week" aria-hidden="true"></i>
            </div>

            <div className="charts__right__cards">
              <div className="card1">
                <h1>New Subscribers</h1>
                <p>{growthStats.new_subs}</p>
              </div>

              <div className="card3">
                <h1>New Owners</h1>
                <p>{growthStats.new_owners}</p>
              </div>

              <div className="card4">
                <h1>Invalid</h1>
                <p>{growthStats.invalids}</p>
              </div>

              <div className="card4">
                <h1>Deactivations</h1>
                <p>{growthStats.deactivations}</p>
              </div>
            </div>
          </div>
   )
}

export default GrowthStatistics;

