import { useState, useEffect } from "react";
import { Navbar, Sidebar } from "../../../../shared";
import Dashboard from "./Dashboard";
import ApiCallHandler from '../../../../utils/network'
import URL from '../../../../utils/constants'
import axios from 'axios'

const baseUrl = URL;

const apiCallResolver = new ApiCallHandler(baseUrl, axios)

const ReportingDashboard = () => {
  //data variables
  const [dashboardStats, setDashboardStats] = useState([])
  const [loading, setLoading] = useState(true)
  const [sidebarOpen, setsidebarOpen] = useState(false)
  
  const openSidebar = () => {
    setsidebarOpen(true);
  };
  const closeSidebar = () => {
    setsidebarOpen(false);
  };

  useEffect(() => {
    async function fetchData() {
      await apiCallResolver.getRequest('/reporting/statistics')
        .then((response) => {
          setDashboardStats(response.data.data)
          setLoading(false)
        }).catch((err) => {
          console.log(err)
          return err
        })
    }

    fetchData()
  }, [])


  return (
    <div className="container">
      <Navbar sidebarOpen={sidebarOpen} openSidebar={openSidebar} />
      <Dashboard data={dashboardStats} loading={loading}/>
      <Sidebar sidebarOpen={sidebarOpen} closeSidebar={closeSidebar} />
    </div>
  );
};

export default ReportingDashboard;