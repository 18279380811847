import "../../components/compliance.css";


const DataManagment = () => {
  return (
    <main>
      <div className="main__container">
        <h4>View Coming Soon. It will be responsible for visualizing and managing the health of the Logic and Data Access Layer.</h4>
      </div>
    </main>
  );
};

export default DataManagment;
