import React from 'react';
import './App.css';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import {AdminDashboard, ReportingDashboard} from "./modules/Dashboards";
import {ComplianceView, DataManagementView, MarketShareView, TelcoInterfaceView, ExcessSim} from "./modules/Reporting";
import {Login} from "./modules/AccessControl";
import {ModalProvider} from 'react-simple-hook-modal';

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <ModalProvider>
            <Route exact path="/" component={Login}/>
            <Route exact path="/dashboard" component={AdminDashboard}/>
            <Route exact path="/reporting" component={ReportingDashboard}/>
            <Route exact path="/compliance" component={ComplianceView}/>
            <Route exact path="/data" component={DataManagementView}/>
            <Route exact path="/marketshare" component={MarketShareView}/>
            <Route exact path="/telcointerface" component={TelcoInterfaceView}/>
            <Route exact path="/excesssim" component={ExcessSim}/>
          </ModalProvider>
        </Switch>
      </BrowserRouter>
    </div>
  )
}

export default App;
