import "../../components/compliance.css";
import {
  Link
} from "react-router-dom";
import ExceptionTable from "./ExceptionTable";


const Compliance = () => {
  return (
    <main>
      <ExceptionTable/>
    </main>
  );
};

export default Compliance;
