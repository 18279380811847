import React, { useMemo, useState, useEffect } from "react";
import 'react-simple-hook-modal/dist/styles.css';
import {
  ModalProvider,
  Modal,
  useModal,
  ModalTransition,
} from 'react-simple-hook-modal';

import Table from "../../../../shared/components/Tables/Table";
import { Loader2 } from "../../../../shared";


import ApiCallHandler from '../../../../utils/network'
import URL from '../../../../utils/constants'
import axios from 'axios'

const baseUrl = URL;

const apiCallResolver = new ApiCallHandler(baseUrl, axios)


function ReportingTable() {
  const { isModalOpen, openModal, closeModal } = useModal();

  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true)
  const [tableLoad, setTableLoad] = useState(false)
  const [selectValueMNOFilter, setSelectValueMNOFilter] = useState(0)
  const [searchTerm, setSearchTerm] = useState('')
  const [idNo, setIdNumber] = useState('')
  const [subscriberSims, setSubscriberSims] = useState([])
  const [offset, setOffset] = useState(0)
  const [searchMode, setSearchMode] = useState(false)
  const [searchData, setSearchData] = useState([])

  useEffect(() => {
    async function fetchTableData() {
      if (selectValueMNOFilter === 0) {
        await apiCallResolver.getRequest(`/reporting/aggregate?limit=500&offset=${offset}`)
          .then((response) => {
            setTableData(response.data.data)
            setLoading(false)
          })
          .catch((err) => {
            console.log(err)
            return err
          })
      } else {
        setTableLoad(true)
        await apiCallResolver.getRequest(`/reporting/aggregate?mno_id=${selectValueMNOFilter}&limit=500&offset=${offset}`)
          .then((response) => {
            setTableData(response.data.data)
            setLoading(false)
            setTableLoad(false)
          })
          .catch((err) => {
            console.log(err)
            return err
          })
      }
    }

    fetchTableData();

  }, [selectValueMNOFilter, searchMode]);


  const updateMNOSelected = e => {
    setTableLoad(true)
    setSelectValueMNOFilter(e.target.value)
    setTableLoad(false)
  }

  const searchSpace = (event) => {
    let keyword = event.target.value;
    if(keyword.length === 0) {
      setSearchMode(false)
      window.location.reload()
    }
    setSearchMode(true)
    setSearchTerm(keyword)
  }

  const fetchSubscriberDetails = async (id_no) => {
    setIdNumber(id_no)
    await apiCallResolver.postRequest('/reporting/simcards', { "nin": id_no })
      .then((response) => {
        setSubscriberSims(response.data.data)
        openModal()
      })
      .catch((err) => {
        console.log(err)
        return err
      })
  }

  const searchByKey = async (key) => {
    const data = {
      "username":"admin@savannah.ug",
      "password":"3W?zHJksT4?{V3o.",
      "method":"searchSubcribers",
      "params":key,
      "max_retrieve":10
    }
    const headers = {'Cookie': 'PHPSESSID=tunufu8oucc1tg68k82jh778i3'}
    const searchResults = await apiCallResolver.postRequestWithHeaders('http://ucc-dashboard.cryptosavannah.com:8081/ucckyc/api.php', data, headers)
    console.log(searchResults)
  }

  const searchDatabase = async (key) => {
    const result = await apiCallResolver.getRequest(`/reporting/search?search=${key}`)
    console.log(result.response)
    console.log(result.data.data)
    setSearchData(result.data.data)
  }

  // const items = Information.filter((data)=>{
  //     if(this.state.search == null)
  //         return data
  //     else if(data.name.toLowerCase().includes(this.state.search.toLowerCase()) || data.country.toLowerCase().includes(this.state.search.toLowerCase())){
  //         return data
  //     }
  //   }).map(data=>{
  //     return(
  //     <div>
  //       <ul>
  //         <li style={{position:'relative',left:'10vh'}}>
  //           <span style={styleInfo}>{data.name}</span>
  //           <span style={styleInfo}>{data.age}</span>
  //           <span style={styleInfo}>{data.country}</span>
  //         </li>
  //       </ul>
  //     </div>
  //     )})


  const columns = useMemo(
    () => [
      {
        // first group - TV Show
        Header: "Personal Details",
        // First group columns
        columns: [
          {
            Header: "First Name",
            accessor: "f_name"
          },
          {
            Header: "Last Name",
            accessor: "s_name"
          }
        ]
      },
      {
        // Second group - Details
        Header: "ID Details",
        // Second group columns
        columns: [
          {
            Header: "ID NUMBER",
            accessor: "id_no"
          },
          {
            Header: "ID TYPE",
            accessor: "id_name"
          },
        ]
      },
      {
        // Third group - Details
        Header: "MNO Details",
        columns: [
          {
            Header: "MNO",
            accessor: "mno_code"
          },
        ]
      },
      {
        Header: "Number of MSISDNs",
        // Second group columns
        // columns: [
        //   {
        //     Header: "MSIDNS HELD",
        //     accessor: "particulars.msidns",
        //     Cell: ({ cell: { value } }) => <Misdns values={value} />
        //   },
        // ]
        columns: [
          {
            Header: "MSISDNs HELD",
            accessor: "sim_count"
          },
          {
            Header: "Actions",
            Cell: ({ cell }) => (
              <button value={cell.row.values.name} onClick={e => fetchSubscriberDetails(cell.row.original.id_no)} className="table__button">
                VIEW
              </button>)
          }
        ]
      }
    ],
    []
  );

  const columnsSearch = useMemo(
    () => [
      {
        // first group - TV Show
        Header: "Personal Details",
        // First group columns
        columns: [
          {
            Header: "First Name",
            accessor: "f_name"
          },
          {
            Header: "Last Name",
            accessor: "s_name"
          }
        ]
      },
      {
        // Second group - Details
        Header: "ID Details",
        // Second group columns
        columns: [
          {
            Header: "ID NUMBER",
            accessor: "id_no"
          },
          {
            Header: "ID TYPE",
            accessor: "id_name"
          },
        ]
      },
      {
        // Third group - Details
        Header: "MNO Details",
        columns: [
          {
            Header: "MNO",
            accessor: "mno_code"
          },
        ]
      },
      {
        Header: "Number of MSISDNs",
        // Second group columns
        // columns: [
        //   {
        //     Header: "MSIDNS HELD",
        //     accessor: "particulars.msidns",
        //     Cell: ({ cell: { value } }) => <Misdns values={value} />
        //   },
        // ]
        columns: [
          // {
          //   Header: "MSISDNs HELD",
          //   accessor: "sim_count"
          // },
          {
            Header: "Actions",
            Cell: ({ cell }) => (
              <button value={cell.row.values.name} onClick={e => fetchSubscriberDetails(cell.row.original.id_no)} className="table__button">
                VIEW OTHER SIMS
              </button>)
          }
        ]
      }
    ],
    []
  );


  return (
    <main>
      <div className="main__container">
        <div className="table__container">
          <h2>Subscriber Report Details by MNO</h2>
          <div className="table__components">

            <select name="slct" id="slct" className='table__select' onChange={updateMNOSelected}>
              <option defaultValue disabled>Filter by MNO</option>
              <option value="0">ALL</option>
              <option value="1">MTN</option>
              <option value="2">UTL</option>
              <option value="3">AIRTEL</option>
              <option value="5">SMILE</option>
              <option value="6">TANGERINE</option>
            </select>
            {/* <select name="slct" id="slct" className='table__select'>
              <option value="" selected disabled>Filter by ID Type</option>
              <option value="1">NATIONAL</option>
              <option value="2">PASSPORT</option>
              <option value="3">REFUGEE</option>
            </select> */}
            <div name="search">
              <input type="text" className="searchTerm" placeholder="Search by MSISDN or ID Number" onChange={(e) => searchSpace(e)} />
              <button type="submit" className="searchButton" onClick={() => searchDatabase(searchTerm)}>
                <i className="fa fa-search"></i>
              </button>
            </div>
          </div>
          {tableLoad ? (
            <Loader2/>
          ) : (
            <div>
              {searchMode ? (
                <Table columns={columnsSearch} data={searchData} loading={loading} />
              ) : (
                <Table columns={columns} data={tableData} loading={loading} />
              )}
            </div>
          )}
            
        </div>
      </div>

      <Modal
        id="any-unique-identifier"
        isOpen={isModalOpen}
        transition={ModalTransition.TOP_DOWN}
      >
        <div className="modal__container">
          <div className="item modal__header">
            <h2>Subscriber ID Number: {idNo}</h2>
          </div>
          <div className="item modal__content">
            <h2>Msisdns Held</h2>
            <ul className="sim__container">
              {
                subscriberSims.map((sim) => {
                  return <li className="badge" key={sim.msisdn}>{sim.msisdn}</li>
                })
              }
            </ul>
          </div>
          <div className="item modal__footer">
            <button className="grid_order table__button" onClick={closeModal}>Close</button>
          </div>
        </div>
      </Modal>
    </main>
  )
}

export default ReportingTable;