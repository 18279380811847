import React, { useMemo, useState, useEffect } from "react";
import {
  ModalProvider,
  Modal,
  useModal,
  ModalTransition,
} from 'react-simple-hook-modal';
import ApiCallHandler from '../../../../utils/network'
import URL from '../../../../utils/constants'


import Table from "../../../../shared/components/Tables/Table";
import axios from 'axios'

const baseUrl = URL;

const apiCallResolver = new ApiCallHandler(baseUrl, axios)

function ExceptionTable() {
    const { isModalOpen, openModal, closeModal } = useModal();
    const [ exceptionData, setExceptionData ] = useState([])
    const [idNo, setIdNumber] = useState('')
    const [exceptionDetail, setExceptionDetail] = useState([])
    const [loading, setLoading] = useState(true)
    const [exceptionloaded, setExceptionLoaded] = useState(false)
    const [mnoFilter, setMnoFilter] = useState(1)

    useEffect(() => {
      async function fetchExceptionData() {
          await apiCallResolver.getRequest(`/reporting/exception/id?limit=250&offset=0&mno_id=${mnoFilter}`)
          .then((response) => {
            setLoading(false)
            setExceptionData(response.data.data)
          })
          .catch((err) => {
            console.log(err)
            return err
          })

      }

      fetchExceptionData();
    }, [mnoFilter]);

    const fetchExceptionDetails = async (id_no) => {
      setIdNumber(id_no)
      await apiCallResolver.postRequest('/reporting/simcards', {"nin": id_no})
      .then((response) => {
          setExceptionDetail(response.data.data)
          setExceptionLoaded(true)
          openModal()
      })
      .catch((err) => {
      console.log(err)
      return err
      })
    }

    const filterByMno = e => {
      setMnoFilter(e.target.value)
    }

    const exportExcelReport = async () => {
      const response = await apiCallResolver.getRequest(`/reporting/exception/export_excel?mno_id=${mnoFilter}`)
      console.log(response)
    }


  const columns = useMemo(
    () => [
      {
        // first group - TV Show
        Header: "Personal Details",
        // First group columns
        columns: [
          {
            Header: "First Name",
            accessor: "f_name"
          },
          {
            Header: "Last Name",
            accessor: "s_name"
          }
        ]
      },
      {
        Header: "MNO Details",
        columns: [
          {
            Header: "MNO Name",
            accessor: "mno_code"
          },
        ]
      },
      {
        // Second group - Details
        Header: "ID Details",
        // Second group columns
        columns: [
          {
            Header: "ID NUM",
            accessor: "id_no"
          },
          {
            Header: "ID TYPE",
            accessor: "id_name"
          },
        ]
      },
      {
        Header: "MSIDN Affected",
        // Second group columns
        columns: [
          {
            Header: "MSIDN",
            accessor: "msisdn",
          },
          {
            Header: "Actions",
            Cell: ({ cell }) => (
              <button value={cell.row.values.name} onClick={e => 
              fetchExceptionDetails(cell.row.original.id_no)} className="table__button">
                  VIEW
              </button>)
          }
        ]
      }
    ],
    []
  );


  return (
    <>
      <div className="main__container">

        {loading ? (
          <h1>loading</h1>
        ) : (
          <div className="table__container">
            <h2>Exception Report Details by MNO</h2>
            <div className="table__components">
              <select name="slct" id="slct" className='table__select' onChange={e => filterByMno(e)}>
                <option defaultValue disabled>Filter by MNO</option>
                <option value="1">MTN</option>
                <option value="2">UTL</option>
                <option value="3">AIRTEL</option>
                <option value="5">SMILE</option>
                <option value="6">TANGERINE</option>
              </select>
             
              <div name="search">
                <input type="text" class="searchTerm" placeholder="Search by MSISDN or ID Number"/>
                <button type="submit" class="searchButton">
                  <i class="fa fa-search"></i>
                </button>
              </div>

              <div class="buttons" onClick={e => exportExcelReport()}>
                <button class="blob-btn">
                  Export To Excel
                  <span class="blob-btn__inner">
                    <span class="blob-btn__blobs">
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                    </span>
                  </span>
                </button>
                <br/>

              {/* <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                <defs>
                  <filter id="goo">
                    <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10"></feGaussianBlur>
                    <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7" result="goo"></feColorMatrix>
                    <feBlend in2="goo" in="SourceGraphic" result="mix"></feBlend>
                  </filter>
                </defs>
              </svg> */}
             </div>

            </div>
              <div>
                <Table columns={columns} data={exceptionData} />
              </div>
          </div>
          )}
        </div>

        <Modal
            id="any-unique-identifier"
            isOpen={isModalOpen}
            transition={ModalTransition.TOP_DOWN}
         >
            <div className="modal__container">
              <div className="item modal__header">
              <h2>Subscriber ID Number: {idNo}</h2>
              </div>
              <div className="item modal__content">
                  <h2>Exception Details</h2>
                  {exceptionloaded ? (
                    <h4 class="badge">Invalid Id used to Register</h4>
                  ) : (
                    <h4 class="badge">Loading</h4>
                  )}
                  
              </div>
              <div className="item modal__footer">
                  <button className="grid_order table__button" onClick={closeModal}>Close</button>
              </div>
          </div>
        </Modal>
    </>
  );
}

export default ExceptionTable;