import React from "react";
import "./Loader.css"

function Loader(){

    return (
        <div class="lds-grid">
            <div>
            </div>
            <div>
            </div>
            <div>
            </div><div></div><div></div><div></div><div></div><div></div><div></div>
        </div>
    )
}

export default Loader;