import { useState, useEffect } from "react";
import "../../components/compliance.css";
import {Pie} from 'react-chartjs-2';
import ApiCallHandler from '../../../../utils/network'
import URL from '../../../../utils/constants'
import axios from 'axios'
import {Loader} from "../../../../shared";

const baseUrl = URL;

const apiCallResolver = new ApiCallHandler(baseUrl, axios)


const TelcoInterface = () => {
    const [idNumber, setIdNumber] = useState("")
    const [loading, setLoading] = useState(false)
    const [status, updateStatus] = useState("")

    const updateIdNumber = e => {
        setIdNumber(e.target.value)
    }

    const simLimit = async () => {
        let data = {
            "id_no": idNumber
        }

        console.log(data)

        let headers = { 
            'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYXlsb2FkIjp7ImVtYWlsX2FkZHJlc3MiOiJrYXBzb25rYXRvbmdvbGVAZ21haWwuY29tIiwidGltZXN0YW1wIjoiMjAyMS0wOC0xMlQxMDo1NTo1MC4yMTRaIn0sImlhdCI6MTYyODc2NTc1MH0.dW4Tl_-r3GXMBxLoGwP-Q2GBknxgMXYt5z6DSyELfUI', 
            'Content-Type': 'application/json'
          }

        await apiCallResolver.postRequestWithHeaders('/reporting/simcards/count', data, headers)
            .then((response) => {
                console.log(response)
                updateStatus(response.data.data.registration_status)
                return true
            }).catch((err) => {
                return err
        })
    }


    return (
        <main>
            {loading ? (
                 <div className="loader__container">
                    <Loader/>
                    <h3>Loading....</h3>
                </div>
            ) : (
        <div className="main__container">
            <h2>Check Sim Limit Status</h2>
            <div className="table__components">
                
            </div>
            <div className="pie__container">
                <div name="search">
                <input type="text" class="searchTerm" placeholder="Insert NIN, Company or Refugee Number" onChange={(e) => updateIdNumber(e)}/>
                <button type="submit" class="searchButton" onClick={() => simLimit()}>
                  <i class="fa fa-search"></i>
                </button>
              </div>
            
            {loading ? (
                 <div className="loader__container">
                    <Loader/>
                    <h3>Loading....</h3>
                </div>
            ) : ( 
                <div className="loader__container">
                    <h3>Sim Registration Status: {status}</h3>
                </div>
            )}

            </div>
        </div>
        )}
        </main>
    );
};

export default TelcoInterface;