import {useState, useEffect} from 'react';
import ApiCallHandler from '../../../../utils/network'
import URL from '../../../../utils/constants'
import axios from 'axios'

const baseUrl = URL;

const apiCallResolver = new ApiCallHandler(baseUrl, axios)

const SubscriberStats = () => {
    const [cardStats, setCardStats] = useState([])
    const [loading, setLoading] = useState(true)
    const [subsFilter, setSubsFilter] = useState({})

    useEffect(() => {
        async function fetchCardsData() {  
          await apiCallResolver.getRequest('/reporting/statistics/subscribers')
            .then((response) => {
              const data = response.data.data
              setCardStats(data.all)
              setSubsFilter(data)
              setLoading(false)
            }).catch((err) => {
              console.log(err)
              return err
            })
        }
    
        fetchCardsData()
      }, [])

    const filterSubsBreakDown = e => {
      const filteredMno = e.target.value
      setCardStats(subsFilter[filteredMno])
    }

    return(
        <div className="charts__left">
            <div className="charts__left__title">
              <div>
                <h1>Subscriber Statistics</h1>
                <p>Subscriber Break Down by Id(to date)</p>
              </div>
              <select name="slct" id="slct" className='table__select' onChange={filterSubsBreakDown}>
                <option defaultValue disabled>Filter by MNO</option>
                <option value="all">ALL</option>
                <option value="mtn">MTN</option>
                <option value="airtel">AIRTEL</option>
                <option value="utl">UTL</option>
                <option value="tangerine">TANGERINE</option>
                <option value="smile">SMILE</option>
              </select>
              <i className="fas fa-calender-week" aria-hidden="true"></i>
            </div>

            <div className="charts__right__cards">
              <div className="card1">
                <h1>National ID</h1>
                <h4>{cardStats.national}</h4>
              </div>

              <div className="card2">
                <h1>Passport</h1>
                <p>{cardStats.passport}</p>
              </div>

              <div className="card3">
                <h1>Refugees</h1>
                <p>{cardStats.refugee}</p>
              </div>

              <div className="card2">
                <h1>Company</h1>
                <p>{cardStats.company}</p>
              </div>
            </div>
          </div>
    )
}

export default SubscriberStats;