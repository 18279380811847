import { useState, useEffect } from "react";
import "../../components/compliance.css";
import {Pie} from 'react-chartjs-2';
import ApiCallHandler from '../../../../utils/network'
import URL from '../../../../utils/constants'
import axios from 'axios'
import {Loader} from "../../../../shared";

const baseUrl = URL;

const apiCallResolver = new ApiCallHandler(baseUrl, axios)


const MarketShare = () => {
    const [pieData, setPieData] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        async function fetchPieData() {  
          await apiCallResolver.getRequest('/reporting/statistics/market')
            .then((response) => {
              setPieData(response.data.data)
              setLoading(false)
            }).catch((err) => {
              console.log(err)
              return err
            })
        }
    
        fetchPieData()
      }, [])

    const getData = () => {
        if(loading){
            return [0, 0, 0, 0, 0, 0]
        }
        return [pieData.airtel.total, pieData.utl.total, pieData.mtn.total, pieData.smile.total, pieData.tangerine.total]
    }

    const data = {
        labels: [
          'Airtel',
          'UTL',
          'MTN',
          'Smile',
          'Tangerine'
        ],
        datasets: [{
          label: 'Market Share Data',
          data: getData(),
          backgroundColor: [
            'rgb(255, 81, 44)',
            'rgb(54, 162, 235)',
            'rgb(255, 205, 86)',
            'rgb(30, 180, 122)',
            'rgb(243, 205, 154)'
          ],
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverOffset: 2,
          responsive: false,
          legend: {
            position: 'bottom',
          },
        }],
      };

    return (
        <main>
            {loading ? (
                 <div className="loader__container">
                    <Loader/>
                    <h3>Loading....</h3>
                </div>
            ) : (
        <div className="main__container">
            <h2>Market Share Summary by Year (2023)</h2>
            <div className="table__components">
                
            </div>
            <div className="pie__container">
                <div className="pie__chart">
                    <Pie data={data} height={200}/>
                </div>

                <ul className="responsive-table">
                    <li className="table-header">
                        <div className="col col-1">MNO</div>
                        <div className="col col-2">Share Percentage</div>
                    </li>
                    <li className="table-row">
                        <div className="col col-1" data-label="Job Id">MTN</div>
                        <div className="col col-2" data-label="Customer Name">{pieData.mtn.percentage}%</div>
                    </li>
                    <li class="table-row">
                        <div className="col col-1" data-label="Job Id">AIRTEL</div>
                        <div className="col col-2" data-label="Customer Name">{pieData.airtel.percentage}%</div>
                    </li>
                    <li class="table-row">
                        <div className="col col-1" data-label="Job Id">SMILE</div>
                        <div className="col col-2" data-label="Customer Name">{pieData.smile.percentage}%</div>
                    </li>
                    <li class="table-row">
                        <div className="col col-1" data-label="Job Id">UTL</div>
                        <div className="col col-2" data-label="Customer Name">{pieData.utl.percentage}%</div>
                    </li>
                    <li class="table-row">
                        <div className="col col-1" data-label="Job Id">TANGERINE</div>
                        <div className="col col-2" data-label="Customer Name">{pieData.tangerine.percentage}%</div>
                    </li>
                </ul>
            </div>
        </div>
        )}
        </main>
    );
};

export default MarketShare;