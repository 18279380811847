class ApiCallHandler{
    constructor(baseUrl, apiLibrary){
        this.baseUrl = baseUrl
        this.apiLibrary = apiLibrary
    }

    async getRequest(absoluteUrl) {
        return await this.apiLibrary.get(this.baseUrl+absoluteUrl)
    }

    async postRequest(absoluteUrl, data) {
        return await this.apiLibrary.post(this.baseUrl+absoluteUrl, data)
    }

    async postRequestWithHeaders(absoluteUrl, data, headers) {
        return await this.apiLibrary.post(this.baseUrl+absoluteUrl, data, headers)
    }
}

export default ApiCallHandler